import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { gtag } from 'lib/gtag';
import { GA_TRACKING_ID, getGtagScript } from 'lib/gtag/gtag';

import { GTagProps } from './GTag.types';

const handleRouteChange = (url: URL) => gtag.pageView(url);

export const GTag = ({ data, children }: GTagProps) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        {GA_TRACKING_ID && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: getGtagScript({
                  clientName: data?.clientName,
                  institutionId: data?.institutionId,
                  institutionName: data?.institutionName,
                  accountName: data?.accountName,
                  accountId: data?.accountId,
                  eventId: data?.eventId,
                  seasonId: data?.seasonId,
                }),
              }}
            />
          </>
        )}
      </Head>
      {children}
    </>
  );
};
