import { gtag } from 'lib/gtag';

import { TypesNotSupportedInAddToAny } from './customEvents.types';

const ADD_TO_ANY_CATEGORY = 'AddToAny';

const customShareEvent = (action: TypesNotSupportedInAddToAny) => {
  gtag.event({
    action,
    category: ADD_TO_ANY_CATEGORY,
    label: action,
    value: document.URL,
  });
};

export const customEvents = {
  sendDownloadEvent: () => customShareEvent(TypesNotSupportedInAddToAny.Download),
  sendInstagramShareEvent: () => customShareEvent(TypesNotSupportedInAddToAny.Instagram),
  // sendTikTokShareEvent: () => customShareEvent(TypesNotSupportedInAddToAny.TikTok),
  sendLinkedInShareEvent: () => customShareEvent(TypesNotSupportedInAddToAny.LinkedIn),
};
