import { GetGtagScriptProps, GTagEvent } from 'lib/gtag/gtag.types';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const getGtagScript = ({
  clientName,
  institutionId,
  institutionName,
  accountName,
  accountId,
  eventId,
  seasonId,
}: GetGtagScriptProps) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${GA_TRACKING_ID}', {
  page_path: window.location.pathname,
  ${!!clientName ? `client_name: "${clientName}",` : ''}
  ${!!institutionId ? `institution_id: "${institutionId}",` : ''}
  ${!!institutionName ? `institution_name: "${institutionName}",` : ''}
  ${!!accountId ? `account_id: "${accountId}",` : ''}
  ${!!accountName ? `account_name: "${accountName}",` : ''}
  ${!!eventId ? `event_id: "${eventId}",` : ''}
  ${!!seasonId ? `season_id: "${seasonId}",` : ''}
  });
  `;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: URL) => {
  if (GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
