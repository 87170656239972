import { config } from '@fortawesome/fontawesome-svg-core';
import { NextPage } from 'next';
import { AppPropsType } from 'next/dist/next-server/lib/utils';
import 'styles/global.scss';
import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { GTag } from 'shared/gTag/GTag';
import { initSentry } from 'utils/sentry';

import 'survey-react/modern.min.css';

initSentry();

config.autoAddCss = false;

type CreatePageComponent<P = any> = NextPage<P> & { layout: React.ComponentType<{ data: any }> };

type MyAppProps = AppPropsType & { Component: CreatePageComponent };

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const Layout = Component.layout || (({ children }) => children);

  useEffect(() => {
    hotjar.initialize(3286915, 6);
  }, []);

  return (
    <GTag data={pageProps.data}>
      <Layout data={pageProps.data}>
        <Component {...pageProps} />
      </Layout>
    </GTag>
  );
};

export default MyApp;
